.dashBtnContainer {
    padding: 10px;
    border-radius: 10px;
    background-color: #1259EF;
    color: #FFF;
    margin: 5px;
    display: inline-flex;
    min-height: 100%;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
}

.dashBtnContainer:hover {
    background-color: #1f365e;
    text-decoration: none;
    cursor: pointer;
}


.dashBtnContainerContent {
    display: flex;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.dashBtnContainerContent:hover {
    text-decoration: none;
}