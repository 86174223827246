.containerFluid {
    width: 100%;
    position: relative;
    height: auto;
    margin-top: 30px;
}

.promotionDetails {
    font-size: 20px;
    line-height: 2rem;
    font-weight: 500;
    color: currentColor;
    margin-bottom: 1.2em;
}

.loadingContainer {
    max-width: 72.5rem;
    margin-left: auto;
    margin-right: auto;
    position: inherit;
}
.promotionTitle {
    color: #343a40;
    font-size: 2.125rem;
    line-height: 1.2;
    color: currentColor;
    font-weight: 700;
    margin: 0 0 1.5rem;
}

.promotionStep {
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 700;
    color: rgba(49,77,77,.5);
    margin-bottom: 0.25rem;
}

.secondStepContainer {
}
