.h1 {
    color: white;
}

.headerContainer {
    background: #1259EF;
    width: 100%;
    padding-top: 122px;
    padding-left: 20vw; 
    position: relative;
}


@media (max-width: 1000px) {
    .headerContainer {
        padding-top: 52px;
        padding-left: 0px; 
    }
}

@media (max-width: 992px) {
    .headerImage {
        display: none;
    }
    .pdfLinkButtonContainer { 
        margin-bottom: 30px;
    }

    .pdfLinkContainer {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
    
}


.headerTextTitle {
    font-size: 38px;
    letter-spacing: -2px;
    word-break: keep-all;
    color: #FFFFFF;
}

.secondaryTextTitle {
    font-size: 22px;
    line-height: 33px;
    color: #FFFFFF; 
}

.pdfLinkContainer {
    background-color: #FFFFFF;
    height: 200px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    text-align: center; 
    margin: auto;
    display: table-cell;
    vertical-align: middle;
}

.pdfContainerFirstText {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    color: #1259EF;

}

.pdfContainerSecondText {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -2px;
    color: #000000;
}
.pdfLinkButtonContainer { 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.pdfLink {
    border: 2px solid #1259EF;
    box-sizing: border-box;
    border-radius: 55px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    width: 175px;
    height: 55px;
    background-color: #FFFFFF;
    align-items: center;
    color: #1259EF;
}

.headerImageContainer {
    margin-left: -160px;
    position: absolute;
    bottom: 0;
}

.headerImage {
    height: 100px;
}

.dashButtons {
    display: inline-block;
    width: 100%;
    margin-top: 60px;
    min-height: 40vh;
}